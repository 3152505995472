import React from 'react';

import { useState } from 'react';

import './Toolbar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';

import { HiShoppingCart, HiSearch, HiOutlineX } from "react-icons/hi";

function Toolbar(props) {
    
    let [showSearch, setShowSearch] = useState(false);

    let [searchTerm, setSearchTerm] = useState('');

    // display search form or not for mobile
    let searchForm = 'toolbar__navigation-search';
    if (showSearch) {
        searchForm = 'toolbar__navigation-search open';
    }

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleShowSearch = () => {
        setShowSearch(!showSearch);
    }

    const handleSearchByTerm = (event) => {
        if (event.key === 'Enter' && event.target.value.length > 0) {
            window.location.href = "/products/search/" + event.target.value;
        }
    }

    const handleSearchByTermClick = () => {
        if (searchTerm.length > 0) {
            window.location.href = "/products/search/" + searchTerm;
        }
    }

    return (
        <header className="toolbar">
            <nav className="toolbar__navigation">
                <div className="toolbar__togle-button">
                    <DrawerToggleButton click={props.drawerClickHandler} />
                </div>
                <div className="toolbar__logo">
                    <a href="/"><img src="/media/logoBlack.png" alt="" /></a>
                </div>
                {/* <div className="spacer1" /> */}
                <div className="toolbar__navigation-items">
                    { showSearch === false && 
                        <button type="submit" className="button-search-phone" onClick={handleShowSearch}><HiSearch /></button>
                    }
                    {showSearch && 
                        <button type="submit" className="button-search-phone" onClick={handleShowSearch}><HiOutlineX /></button>
                    }
                    <div className={searchForm}>
                        <input
                            type="text"
                            id="header-search"
                            placeholder="Cauta produsul dorit"
                            name="search"
                            onChange={handleInputChange}
                            onKeyDown={handleSearchByTerm}
                        />
                        <button type="submit" className="button-search" onClick={handleSearchByTermClick} ><HiSearch /></button>
                    </div>
                </div>
                <div className="toolbar__cart-items">
                    <ul>
                        <li>
                            <a href="/cart">
                                <HiShoppingCart /> 
                                <span className="toolbar__cart-description">Cos de cumparaturi</span>
                                {props.cart.products.length > 0 && <span>({props.cart.products.length})</span> }
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Toolbar;