import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import Products from './pages/Products/Products';
import Product from './pages/Product/Product';
import Cart from './pages/Cart/Cart';
import OrderDetails from './pages/OrderDetails/OrderDetails';

import AdminLogin from './pages/AdminLogin/AdminLogin';
import AdminHome from './pages/AdminHome/AdminHome';

import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import Footer from './components/Footer/Footer';

import ReactGA from 'react-ga4';

import './App.css';

const App = props => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const [initialized, setInitialized] = useState(false);

  // google analytics
  if (!window.location.href.includes("localhost")) { 
    const TRACKING_ID = "G-ZS6KCY5ZX4";
    ReactGA.initialize(TRACKING_ID);
  }

  // cart items
  let [cart, setCart] = useState({
    products: [],
    total: 0,
    voucher: ''
  });
  
  let localCart = localStorage.getItem("cart");

  useEffect(() => {
    // google analytics
    ReactGA.pageview(window.location.pathname + window.location.search);
    //turn it into js
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (localCart) {
      if (localCart.products !== null && localCart.products !== undefined) {
        setCart(localCart);
      } else {
        localStorage.removeItem("cart");
      }
    }

  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [initialized, window.location]);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  }

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  }

  let backdrop;
  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  // display header search bar only if admin is false
  let header;
  if (localStorage.getItem("isAdmin") === null || localStorage.getItem("isAdmin") === "false") {
    header = <><Toolbar drawerClickHandler={drawerToggleClickHandler} cart={cart} />
              <SideDrawer show={sideDrawerOpen}  />
              {backdrop}
            </>;
  }

  return (
    <div style={{position: 'relative', 'minHeight': '100vh'}}>
      {header}

      <Router>
        <Routes>
          {/* User */}
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile /> } />
          <Route path="/products/gender/:productsGender" element={<Products />} />
          <Route path="/products/search/:searchTerm" element={<Products />} />
          <Route path="/products/category/:productsCategory" element={<Products />} />
          <Route path="/products/gender/:productsGender/product/:productCode" element={<Product cart={cart} setCart={setCart} />} />
          <Route path="/cart" element={<Cart cart={cart} setCart={setCart} />} />
          <Route path="/order/details/:status" element={<OrderDetails cart={cart} setCart={setCart} />} />

          {/* Admin */}
          <Route path="/admin/login" element={<AdminLogin /> } />
          <Route path="/admin/home" element={<AdminHome /> } />
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}
          
{/* <Route path="/admin/masab/login" element={<AdminLogin />} /> */}

export default App;
