import React from 'react';

import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { FiInstagram } from "react-icons/fi";
import { GrFacebook } from "react-icons/gr";

import classes from './Footer.module.css';

const Footer = props => {

    return (
        <div className={classes.Footer}>
            <div className={classes.Content}>
                <div className={classes.Contact}>
                    <div>
                        <h4>Contact</h4>
                    </div>
                    <ul className={classes.List}>
                        <li>
                            Strada Margaretelor 48, 077180
                        </li>
                        <li>
                            <a href="tel:0725657251"><HiOutlinePhone /> 0725 657 251</a>
                        </li>
                        <li>
                            <a href="mailto:contact.babiesjoy@gmail.com"><HiOutlineMail />contact.babiesjoy@gmail.com</a>
                        </li>
                    </ul>
                </div>
                <div className={classes.Contact}>
                    <div>
                        <h4>Socializare</h4>
                    </div>
                    <ul className={`${classes.List} ${classes.Social}`}>
                        <li>
                            <a href="https://www.instagram.com/babiesjoy_/" target="_blank" rel="noopener noreferrer"><FiInstagram /></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/Babies-Joy-101762932488671" target="_blank" rel="noopener noreferrer"><GrFacebook /></a>
                        </li>
                    </ul>
                </div>
                <div className={classes.Contact}>
                    <div>
                        <h4>Informatii</h4>
                    </div>
                    <ul className={classes.List}>
                        <li>
                            <a href="/media/documents/termeni-conditii.pdf" target="_blank" rel="noopener noreferrer">Termeni si conditii</a>
                        </li>
                        <li>
                            <a href="/media/documents/politica-confidentialitate.pdf" target="_blank" rel="noopener noreferrer">Politica de confidentialitate</a>
                        </li>
                        <li>
                            <a href="https://anpc.ro/" target="_blank" rel="noopener noreferrer">Protectia consumatorului</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={classes.Copyright}>
                <h5>© 2023 Copyright BabiesJoy Clothing SRL, CUI: 46314400, Reg. Com. J23/3935/2022</h5>
            </div>
        </div>
    );

}

export default Footer;