import axios from 'axios';

const prod = axios.create({
    baseURL: process.env.REACT_APP_SERVICES_URL
});

const dev = axios.create({
    baseURL: 'http://localhost:8080'
    // baseURL: 'http://192.168.0.204:8080'
});
   
// TODO based on it/ro I have to return specific restApi
export const restApi = process.env.NODE_ENV === 'development' ? dev : prod;


// TODO very useful for different configs per domain
// https://www.architect.io/blog/2022-08-16/react-environment-variables-developers-guide/
// https://stackoverflow.com/questions/42458434/how-to-set-build-env-variables-when-running-create-react-app-build-script -> 61
// basically I have to create .env.it and .env.ro config files
// then update jenkins to do npm run build:it and npm run build:ro 