import React from 'react';

import { useEffect, useState } from 'react';
import { restApi } from '../../api/Source';

import './AdminHome.css';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';


import classes from './AdminHome.module.css';

const AdminHome = props => {

    // the products as received from Backend
    const [products, setProducts] = useState([]);
    // the reviews as received from Backend
    const [reviews, setReviews] = useState([]);
    // the vouchers as received from Backend
    const [vouchers, setVouchers] = useState([]);
    // display only active products or include inactive
    const [activeProducts, setActiveProducts] = useState(true);
    // the orders as received from Backend
    const [orders, setOrders] = useState([]);
    // the main menu selected
    const [selectedMenu, setSelectedMenu] = useState('');
    // if request is in progress
    const [isBusy, setBusy] = useState(true);
    // generate voucher
    const [voucherPercentage, setVoucherPercentage] = useState(0);
    const [voucherCode, setVoucherCode] = useState();

    let ageMapping = {
        1: "0-3 luni", 
        2: "3-6 luni", 
        3: "6-9 luni",
        4: "9-12 luni",
        5: "Standard",
        6: "0-1 ani",
        7: "1-2 ani",
        8: "2-3 ani",
        9: "3-4 ani",
        11: "4-5 ani",
    };

    // get products by gender
    const handleGetProductsByGender = (gender) => {
        setBusy(true);
        setActiveProducts(true);
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.get("/admin/products?gender=" + gender, config).then(res => {
            setProducts(res.data);
            setBusy(false);
        })
    }

    // get orders by status
    const handleGetOrders = (orderStatus) => {
        setBusy(true);
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.get("/admin/orders?orderStatus=" + orderStatus, config).then(res => {
            setOrders(res.data);
            setBusy(false);
        })
    }

    // get reviews
    const handleGetAllReviews = () => {
        setBusy(true);
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.get("/admin/reviews", config).then(res => {
            setReviews(res.data);
            setBusy(false);
            setSelectedMenu('reviews');
        })
    }

    // get vouchers
    const handleGetAllVouchers = () => {
        setBusy(true);
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.get("/admin/vouchers", config).then(res => {
            setVouchers(res.data);
            setBusy(false);
            setSelectedMenu('vouchers');
        })
    }

    // update stock for product in state
    const handleOnChangeUpdateStock = (product, index, e) => {
        // update the age object
        const newStock = product.age.map((item, i) => {
            if (index === i) {
                // age which has stock and we need to update quantity
                return { ...item, 
                    stock: {
                        ...item.stock, "quantity": parseInt(e.target.value, 10)
                    }
                }
            } else {
                return item;
            }
        });
        product.age = newStock;
        // update state
        const updatedProducts = products.map((stateProduct) => {
            if (stateProduct.code === product.code) {
                return {...stateProduct, product};
            } else {
                return stateProduct;
            }
        });
        setProducts(updatedProducts);
    }

    // update review status
    const handleOnChangeUpdateReview = (review, e) => {

        review.status = e.target.value;
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.put("/admin/reviews", review, config).then(res => {
            console.log("Updated successfully");
        }).catch(error => {
            console.log("Something has failed");
        });

        // update the reviews object
        const newReviewStatus = reviews.map((item) => {
            if (item.id === review.id) {
                // review which we need to update status
                return { ...item, "status": e.target.value}
            } else {
                return item;
            }
        });
        setReviews(newReviewStatus);
    }

    // update voucher
    const handleOnChangeUpdateVoucher = (voucher, detail, e) => {
        switch(detail) {
            case 'status':
                voucher.voucherStatus = e.target.value;
                break;
            case 'discount':
                voucher.discount = parseFloat(e.target.value);
                break;
            case 'code':
                voucher.code = e.target.value;
                break;
            case 'type':
                voucher.voucherType = e.target.value;
                break;
            case 'voucherDiscount':
                voucher.voucherDiscount = e.target.value;
                break;
            default:
                break;
        }

        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        console.log(voucher);
        restApi.put("/admin/vouchers", voucher, config).then(res => {
            console.log("Updated successfully");
        }).catch(error => {
            console.log("Something has failed");
        });

        // update state
        const updatedVouchers = vouchers.map((stateVoucher) => {
            if (stateVoucher.id === voucher.id) {
                return {...stateVoucher, voucher};
            } else {
                return stateVoucher;
            }
        });
        setVouchers(updatedVouchers);
        
    }

    const handleOnChangeUpdateProduct = (product, detail, e) => {
        switch(detail) {
            case 'status':
                product.status = e;
                break;
            case 'price':
                product.price.value = e.target.value;
                break;
            case 'discount':
                product.price.discount = e.target.value;
                break;
            case 'priority':
                product.priority = e.target.value;
                break;
            case 'name':
                product.name = e.target.value;
                break;
            default:
                break;
        }

        // update state
        const updatedProducts = products.map((stateProduct) => {
            if (stateProduct.code === product.code) {
                return {...stateProduct, product};
            } else {
                return stateProduct;
            }
        });
        setProducts(updatedProducts);
    }

    const handleUpdateProduct = (product) => {
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.put("/admin/products/update", product, config).then(res => {
            console.log("Updated successfully");
        }).catch(error => {
            console.log("Something has failed");
        });
    }

    const handleActivateOrDeactivateProduct = (product) => {
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        
        if (product.status === 'INACTIVE') {
            handleOnChangeUpdateProduct(product, 'status', 'ACTIVE');
        } else {
            handleOnChangeUpdateProduct(product, 'status', 'INACTIVE');
        }

        restApi.put("/admin/products/update", product, config).then(res => {
            console.log("Updated successfully");
        }).catch(error => {
            console.log("Something has failed");
        });
    }

    const handleArchiveProduct = (product) => {
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        
        handleOnChangeUpdateProduct(product, 'status', 'ARCHIVED');

        restApi.put("/admin/products/" + product.code + "/archive", null, config).then(res => {
            console.log("Updated successfully");
        }).catch(error => {
            console.log("Something has failed");
        });

    }

    const handleSelectMenu = (menu) => {
        if (menu === 'reviews') {
            handleGetAllReviews();
        } else if (menu === 'vouchers') {
            handleGetAllVouchers();
        } else {
            setSelectedMenu(menu);
        }
    }


    // ORDERS
    const handleOnChangeUpdateOrder = (item, userOrders, e) => {
        const itemValue = e.target.value;

        // update state
        userOrders.map((userOrder) => {
            switch(item) {
                case 'awb':
                    userOrder.awb = itemValue;
                    break;
                case 'status':
                    userOrder.orderStatus = itemValue;
                    break;
                default:
                    break;
            }
        });

        const updatedOrders = orders.map((stateOrder) => {
            if (stateOrder.orders[0].id === userOrders[0].id) {
                stateOrder.orders = userOrders;
                return stateOrder;
            } else {
                return stateOrder;
            }
        });

        setOrders(updatedOrders);
    }

    const handleUpdateOrder = (order) => {
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.put("/admin/orders/update", order, config).then(res => {
            console.log("Updated successfully");
        }).catch(error => {
            console.log("Something has failed");
        });
    }

    const handleRequestReview = (order) => {
        const auth = localStorage.getItem("Authorization");
        const config = {
            headers: {
              Authorization: `${auth}`,
            },
        };
        restApi.post("/admin/orders/reviews/request/user/" + order.user.id + "/order/" + order.orders[0].orderNumber, null, config).then(res => {
            console.log("Requested successfully");
        }).catch(error => {
            console.log("Something has failed");
        });
    }

    const handleGenerateVoucher = () => {
        if (voucherPercentage > 0) {
            const auth = localStorage.getItem("Authorization");
            const config = {
                headers: {
                Authorization: `${auth}`,
                },
            };
            restApi.post("/admin/vouchers/generate/" + voucherPercentage, null, config).then(res => {
                console.log("Generated successfully");
                setVoucherCode(res.data);
            }).catch(error => {
                console.log("Something has failed");
                setVoucherCode();
            });
        }
    }

    const handleDisplayInactiveProducts = () => {
        setActiveProducts(!activeProducts);
    }

    return (
        <main style={{marginTop: '64px'}}>
            {/* MAIN MENU */}
            <nav className={classes.MainMenu}>
                <ul className={classes.Items}>
                    <li>
                        <button onClick={() => handleSelectMenu("products")}>Produse</button>
                    </li>
                    <li>
                        <button onClick={() => handleSelectMenu("orders")}>Comenzi</button>
                    </li>
                    <li>
                        <button onClick={() => handleSelectMenu("vouchers")}>Voucher</button>
                    </li>
                    <li>
                        <button onClick={() => handleSelectMenu("reviews")}>Review-uri</button>
                    </li>
                </ul>
            </nav>
            {/* PRODUCTS MENU */}
            {selectedMenu === 'products' && 
                <div className={classes.ProductsMenu}>
                    <nav>
                        <ul className={classes.Items}>
                            <li>
                                <button onClick={() => handleGetProductsByGender("boy")}>Baieti</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetProductsByGender("girl")}>Fete</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetProductsByGender("baptism")}>Produse Botez</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetProductsByGender("bed")}>Accesorii Patut</button>
                            </li>
                            {/* <li>
                                <button onClick={() => handleGetProductsByGender("party")}>Arcade Baloane</button>
                            </li> */}
                        </ul>
                    </nav>
                    {isBusy === false && 
                        <div className={classes.Content}>
                            <button onClick={() => handleDisplayInactiveProducts()}>Afiseaza Produse Inactive</button>
                            <Accordion allowMultipleExpanded="true" allowZeroExpanded="true">
                                {products.map((product) => {
                                    if ((activeProducts === true && product.status === 'ACTIVE') ||
                                        (activeProducts === false && product.status === 'INACTIVE')) {
                                        return <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton className={`accordion__button ${product.status}`}>
                                                    <h4 className={classes.ProductName}>{product.name}</h4>
                                                    <img className={classes.ProductImage} src={product.images[0].urlQuick} />
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div>
                                                    <h4>Detalii Produs</h4>
                                                    <table className={classes.ProductsTable}>
                                                        <tbody>
                                                            <tr>
                                                                <th>Cod</th>
                                                                <th>Nume</th>
                                                                <th>Culoare</th>
                                                                <th>Categorie</th>
                                                                <th>Prioritate</th>
                                                                <th>Pret</th>
                                                                <th>Discount</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{product.code}</td>
                                                                <td><input type="text" value={product.name} onChange={(e) => handleOnChangeUpdateProduct(product, 'name', e)} /></td>
                                                                <td>{product.color}</td>
                                                                <td>{product.category}</td>
                                                                <td><input type="text" value={product.priority} onChange={(e) => handleOnChangeUpdateProduct(product, 'priority', e)} /></td>
                                                                <td><input type="text" value={product.price.value} onChange={(e) => handleOnChangeUpdateProduct(product, 'price', e)} /></td>
                                                                <td><input type="text" value={product.price.discount} onChange={(e) => handleOnChangeUpdateProduct(product, 'discount', e)} /></td> 
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <h4>Detalii Stoc</h4>
                                                    <table className={classes.ProductsTable}>
                                                        <tbody>
                                                            <tr>
                                                                <th>Marime</th>
                                                                <th>Stoc</th>
                                                            </tr>
                                                            {product.age.map((age, index) => (
                                                                <tr>
                                                                    <td>{ageMapping[age.stock.ageId]}</td>
                                                                    <td><input type="text" value={age.stock.quantity} onChange={(e) => handleOnChangeUpdateStock(product, index, e)} /></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    <button onClick={() => handleUpdateProduct(product)}>Actualizeaza</button>
                                                    {product.status === 'INACTIVE' && 
                                                        <button onClick={() => handleActivateOrDeactivateProduct(product)}>Activeaza</button>
                                                    }
                                                    {product.status === 'ACTIVE' && 
                                                        <button onClick={() => handleActivateOrDeactivateProduct(product)}>Dezactiveaza</button>
                                                    }
                                                    <button onClick={() => handleArchiveProduct(product)}>Arhiveaza Produsul</button>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    }
                                })}
                            </Accordion>
                        </div>
                    }
                </div>
            }
            {/* ORDERS MENU */}
            {selectedMenu === 'orders' && 
                <div className={classes.OrdersMenu}>
                    <nav>
                        <ul className={classes.Items}>
                            <li>
                                <button onClick={() => handleGetOrders("PENDING")}>Noi</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetOrders("CONFIRMED")}>Procesate</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetOrders("DELIVERY_IN_PROGRESS")}>In livrare</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetOrders("DELIVERY_DONE")}>Livrate</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetOrders("RETURNED")}>Returnate</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetOrders("FAILED_PAYMENT")}>Plata nereusita</button>
                            </li>
                            <li>
                                <button onClick={() => handleGetOrders("CANCELLED")}>Anulate</button>
                            </li>
                        </ul>
                    </nav>
                    {isBusy === false && 
                        <div className={classes.Content}>
                            <Accordion allowMultipleExpanded="true" allowZeroExpanded="true">
                                {orders.map((order) => (
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {order.user.firstName} {order.user.lastName}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div>
                                                <h4>Detalii Client</h4>
                                                <table className={classes.OrdersTable}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Nume</th>
                                                            <th>Telefon</th>
                                                            <th>Adresa</th>
                                                            <th>Mail</th>
                                                        </tr>
                                                        <tr>
                                                            <td>{order.user.firstName} {order.user.lastName}</td>
                                                            <td>{order.user.phone}</td>
                                                            <td>{order.user.address}</td>
                                                            <td>{order.user.email}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div>
                                                <h4>
                                                    Detalii Comanda
                                                </h4>
                                                <table className={classes.OrdersTable}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Produs</th>
                                                            <th>Cod Produs</th>
                                                            <th>Cantitate</th>
                                                            <th>Marime</th>
                                                            <th>Pret</th>
                                                        </tr>
                                                        {order.orders.map((userOrder) => (
                                                            <tr>
                                                                {/* TODO temporar */}
                                                                <td><a href={'https://babiesjoy.ro/products/gender/girl/product/' + userOrder.productCode} target="_blank">Acceseaza produs</a> </td>
                                                                <td>{userOrder.productCode}</td>
                                                                <td>{userOrder.quantity}</td>
                                                                <td>{ageMapping[userOrder.productAgeId]}</td>
                                                                <td>{userOrder.priceTotalNoTva}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className={classes.Status}>
                                                    <select value={order.orders[0].orderStatus} onChange={(e) => handleOnChangeUpdateOrder('status', order.orders, e)}>
                                                        <option value="PENDING">Comanda Noua</option>
                                                        <option value="CONFIRMED">Comanda Confirmata</option>
                                                        <option value="DELIVERY_IN_PROGRESS">Comanda In Livrare</option>
                                                        <option value="DELIVERY_DONE">Comanda Livrata</option>
                                                        <option value="RETURNED">Comanda Returnata</option>
                                                        <option value="FAILED_PAYMENT">Comanda Plata Incompleta</option>
                                                        <option value="CANCELLED">Comanda Anulata</option>
                                                    </select>
                                                </div>
                                                <div className={classes.awb}>
                                                    <input type="text" value={order.orders[0].awb} placeholder="Actualizeaza AWB" onChange={(e) => handleOnChangeUpdateOrder('awb', order.orders, e)} />
                                                </div>
                                                <button onClick={() => handleUpdateOrder(order)}>Actualizeaza</button>
                                            </div>
                                            <div>
                                                <h4>
                                                    Voucher
                                                </h4>
                                                <p>{order.voucher != null ? order.voucher.code + " " + order.voucher.discount + "%" : "Nu exista"}</p>
                                            </div>
                                            {/* only ask for review if it was not asked before */}
                                            {order.orders[0].reviewRequested === false &&
                                                <div>
                                                    <button onClick={() => handleRequestReview(order)}>Cere Review</button>
                                                </div>
                                            }
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </div>
                    }
                </div>
            }
            {/* VOUCHERS MENU */}
            {selectedMenu === 'vouchers' && 
                <div className={classes.VouchersMenu}>
                    <div className={classes.Content}>
                        <div className={classes.GenerateVoucher}>
                            <input type="text" value={voucherPercentage} onChange={(e) => setVoucherPercentage(e.target.value)} />
                            <button onClick={() => handleGenerateVoucher()}>Genereaza Voucher</button>
                            {voucherCode !== null &&
                                <p>{voucherCode}</p>
                            }
                        </div>
                        <div className={classes.ViewUpdateVoucher}>
                            <table className={classes.VouchersTable}>
                                <tbody>
                                    <tr>
                                        <th>Cod</th>
                                        <th>Tip</th>
                                        <th>Discount</th>
                                        <th>Tip Discount</th>
                                        <th>Status</th>
                                    </tr>
                                    {vouchers.map((voucher) => (
                                        <tr>
                                            <td><input type="text" value={voucher.code} onChange={(e) => handleOnChangeUpdateVoucher(voucher, 'code', e)} /></td>
                                            <td>
                                                <select value={voucher.voucherType} onChange={(e) => handleOnChangeUpdateVoucher(voucher, 'type', e)}>
                                                    <option value="UNIQUE">Unic</option>
                                                    <option value="DELIVERY">Livrare</option>
                                                    <option value="PROMOTION">Promotie</option>
                                                    <option value="INFLUENCER">Influencer</option>
                                                </select>
                                            </td>
                                            <td><input type="number" value={voucher.discount} onChange={(e) => handleOnChangeUpdateVoucher(voucher, 'discount', e)} /></td>
                                            <td>
                                                <select value={voucher.voucherDiscount || ''} onChange={(e) => handleOnChangeUpdateVoucher(voucher, 'voucherDiscount', e)}>
                                                    <option value=''></option>
                                                    <option value="AMOUNT">Suma</option>
                                                    <option value="PERCENTAGE">Procent</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select value={voucher.voucherStatus} onChange={(e) => handleOnChangeUpdateVoucher(voucher, 'status', e)}>
                                                    <option value="APPLIED">Inactiv</option>
                                                    <option value="AVAILABLE">Activ</option>
                                                </select>
                                            </td>
                                            {/* <td>
                                                <select value={review.status} onChange={(e) => handleOnChangeUpdateReview(review, e)}>
                                                    <option value="ACTIVE">Activ</option>
                                                    <option value="INACTIVE">Inactiv</option>
                                                </select>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {/* REVIEWS MENU */}
            {selectedMenu === 'reviews' && 
                <div className={classes.ReviewsMenu}>
                    <div className={classes.Content}>
                    <table className={classes.ReviewsTable}>
                        <tbody>
                            <tr>
                                <th>Nume produs</th>
                                <th>Nume Client</th>
                                <th>Nota</th>
                                <th>Feedback</th>
                                <th>Data</th>
                                <th>Status</th>
                            </tr>
                            {reviews.map((review, index) => (
                                <tr>
                                    <td><a href={review.productUrl} target="_blank">{review.productName}</a></td>
                                    <td>{review.userName}</td>
                                    <td>{review.mark}</td>
                                    <td>{review.feedback}</td>
                                    <td>{review.createdDate}</td>
                                    <td>
                                        <select value={review.status} onChange={(e) => handleOnChangeUpdateReview(review, e)}>
                                            <option value="ACTIVE">Activ</option>
                                            <option value="INACTIVE">Inactiv</option>
                                        </select>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
            }

        </main>
    )
}

export default AdminHome;