import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { restApi } from '../../api/Source';
import { ThreeDots } from "react-loader-spinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BiFilter } from "react-icons/bi";


import ReactGA from 'react-ga4';

import 'react-lazy-load-image-component/src/effects/blur.css';
import classes from './Products.module.css';
import './Products.css';

// ATENTIE! https://blog.logrocket.com/4-ways-to-render-large-lists-in-react/ pentru infinite scroll
// Atentie! Pentru /products/{productId} verifica https://www.youtube.com/watch?v=UjHT_NKR_gU&t=5s&ab_channel=PedroTech min 18!!!
function Products(props) {

    // the gender of the products page
    const { productsGender } = useParams();

    // the category of the products page
    const { productsCategory } = useParams();

    // if request is in progress
    const [isBusy, setBusy] = useState(true);

    // the products as received from Backend
    const [products, setProducts] = useState([]);

    // the filters are open on mobile
    const [filtersOpen, setFiltersOpen] = useState(false);

    // the filtered products
    const [filteredProducts, setFilteredProducts] = useState([]);

    // the categories received with the active filter property
    const [categories, setCategories] = useState([]);

    // the genders received with the active filter property
    const [gender, setGender] = useState([]);

    // the colors received with the active filter property
    const [colors, setColors] = useState([]);

    // google analytics
    const [initialized, setInitialized] = useState(false);

    // infinite scroll
    const [count, setCount] = useState({
        prev: 0,
        next: 8
    });
    const [hasMore, setHasMore] = useState(true);

    const getMoreData = () => {
        if (isAnyFilterApplied() === true) {
            setHasMore(false);
            return;
        }
        if (filteredProducts.length === products.length) {
          setHasMore(false);
          return;
        }
        setTimeout(() => {
          setFilteredProducts(filteredProducts.concat(products.slice(count.prev + 8, count.next + 8)))
        }, 300)
        setCount((prevState) => ({ prev: prevState.prev + 8, next: prevState.next + 8 }))
    }
  

    // google analytics
    if (!window.location.href.includes("localhost")) { 
        const TRACKING_ID = "G-ZS6KCY5ZX4";
        ReactGA.initialize(TRACKING_ID);
    }

    useEffect(() => {
        window.scroll(0, 0);
        if (window.location.href.includes('/gender')) {
            document.title = "Products gender " + productsGender;
        } else if (window.location.href.includes('category')) {
            document.title = "Products category " + productsCategory;
        } else if (window.location.href.includes('/search')) {
            if (window.location.href.split("/search/") !== undefined && window.location.href.split("/search/") !== null && window.location.href.split("/search/")[1] !== null && window.location.href.split("/search/")[1] !== undefined) {
                document.title = "Products search " + window.location.href.split("/search/")[1];
            }
        }
        ReactGA.pageview(window.location.pathname + window.location.search);
        if (window.location.href.includes('/gender')) {
            if (productsGender === null || productsGender === undefined) {
                window.location.href = "/products/gender/girl";
            }
            restApi.get("/products?gender=" + productsGender).then(res => {
                setProducts(res.data);
                setFilteredProducts(res.data.slice(count.prev, count.next));
                res.data.forEach((product) => {
                    if (categories.some(category => category.category === product.category) === false) {
                        categories.push({category: product.category, active: false});
                    }
                    if (gender.some(gender => gender.gender === product.gender) === false) {
                        gender.push({gender: product.gender, active: false});
                    }
                    if (colors.some(color => color.color === product.color) === false) {
                        colors.push({color: product.color, active: false});
                    }
                });
                setBusy(false);
            })
        } else if (window.location.href.includes('category')) {
            restApi.get("/products?productCategory=" + productsCategory).then(res => {
                setProducts(res.data);
                setFilteredProducts(res.data.slice(count.prev, count.next));
                res.data.forEach((product) => {
                    if (categories.some(category => category.category === product.category) === false) {
                        categories.push({category: product.category, active: false});
                    }
                    if (gender.some(gender => gender.gender === product.gender) === false) {
                        gender.push({gender: product.gender, active: false});
                    }
                    if (colors.some(color => color.color === product.color) === false) {
                        colors.push({color: product.color, active: false});
                    }
                });
                setBusy(false);
            })
        } else if (window.location.href.includes('/search')) {
            restApi.get("/products?searchTerm=" + window.location.href.split("/search/")[1]).then(res => {
                setProducts(res.data);
                setFilteredProducts(res.data.slice(count.prev, count.next));
                res.data.forEach((product) => {
                    if (categories.some(category => category.category === product.category) === false) {
                        categories.push({category: product.category, active: false});
                    }
                    if (gender.some(gender => gender.gender === product.gender) === false) {
                        gender.push({gender: product.gender, active: false});
                    }
                    if (colors.some(color => color.color === product.color) === false) {
                        colors.push({color: product.color, active: false});
                    }
                });
                setBusy(false);
            })
        }
    }, []);

    useEffect(() => {
        if (initialized) {
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [initialized, window.location]);

    let categoryMapping = {
        DRESS: "ROCHITE",
        BOLERO_COAT: "BOLERO & PALTONAS",
        BODY: "BODYURI",
        SUIT: "COSTUME",
        SHOE: "INCALTAMINTE",
        JACKET: "GECI",
        SWEATER: "PULOVERE",
        HAT: "CACIULI",
        SOCK: "SOSETE",
        ACCESSORIES: "ACCESORII",
        CANDLE: "LUMANARI",
        TROUSSEAU: "TRUSOU",
        BOX: "CUTII SI CUFERE",
        TROUSSEAU_SET: "SETURI TRUSOU",
        CROSSES: "CRUCIULITE",
        PILLOW: "PERNE",
        LINEN: "LENJERIE"
    };

    let genderMapping = {
        BOY: "Baiat",
        GIRL: "Fata",
        UNISEX_JUNIOR: "Unisex",
        MAN: "Barbat",
        WOMAN: "Femeie",
        BAPTISM: "Botez",
        BED: "Pat"
    };

    let colorMapping = {
        RED: "Rosu",
        BLUE: "Albastru",
        WHITE: "Alb",
        PINK: "Roz",
        ORANGE: "Portocaliu",
        BEIGE: "Bej",
        GREY: "Gri",
        MAUVE: "Mov",
        YELLOW: "Galben",
        GREEN: "Verde",
        TRADITIONAL: "Traditional"
    };

    // apply all filters
    const applyFilters = () => {
        // create an array with active filters
        let categoryFilters = [];
        let genderFilters = [];
        let colorFilters = [];
        categories.forEach((category) => {
            if (category.active === true) {
                categoryFilters.push(category.category);
            }
        });
        gender.forEach((gender) => {
            if (gender.active === true) {
                genderFilters.push(gender.gender);
            }
        });
        colors.forEach((color) => {
            if (color.active === true) {
                colorFilters.push(color.color);
            }
        });

        setFilteredProducts(products.filter((product) => categoryFilters.length > 0 ? categoryFilters.some(category => product.category === category) : product)
                                    .filter((product) => genderFilters.length > 0 ? genderFilters.some(gender => product.gender === gender) : product)
                                    .filter((product) => colorFilters.length > 0 ? colorFilters.some(color => product.color === color) : product));
    }

    const isAnyFilterApplied = () => {
        let isFilterApplied = false;
        categories.forEach((category) => {
            if (category.active === true) {
                isFilterApplied = true;
            }
        });
        gender.forEach((gender) => {
            if (gender.active === true) {
                isFilterApplied =  true;
            }
        });
        colors.forEach((color) => {
            if (color.active === true) {
                isFilterApplied = true;
            }
        });
        return isFilterApplied;
    }

    // when filter checked, false->true and the other way
    const handleCategoryChange = (selectedCategory) => {
        categories.forEach((category) => {
            if (category.category === selectedCategory) {
                category.active = !category.active;
            } else {
                category.active = false;
            }
            applyFilters();
        });
    }

    // when filter checked, false->true and the other way
    const handleGenderChange = (e) => {
        gender.forEach((gender) => {
            if (gender.gender === e.target.value) {
                gender.active = !gender.active;
                applyFilters();
            }
        })
    }

    // when filter checked, false->true and the other way
    const handleColorChange = (e) => {
        colors.forEach((color) => {
            if (color.color === e.target.value) {
                color.active = !color.active;
                applyFilters();
            }
        })
    }

    // (mobile) when clicking on filters, show or hide them
    const filterToggleClickHandler = () => {
        setFiltersOpen(!filtersOpen);
    }

    let filterClasses = classes.Filters;
    let b = document.body;
    if (filtersOpen) {
        filterClasses = classes.Filters + ' ' + classes.Open;
        b.style.overflow = 'hidden';
    } else {
        b.style.overflow = 'auto';
    }
    
    return (
        <main style={{marginTop: '64px'}}>
            {isBusy === false && 
            <InfiniteScroll
                dataLength={filteredProducts.length}
                next={getMoreData}
                hasMore={hasMore}
            >
                <div className={classes.Content}>
                    <div className={filterClasses}>
                        <button className={classes.ShowFiltersResults} onClick={filterToggleClickHandler}>Afiseaza</button>
                        {gender.length > 1 && 
                            <div className={classes.SizeFilter}>
                                <div className={classes.Header}>
                                    <span>GEN</span>
                                </div>
                                <div className={classes.Filter}>
                                    {gender.map((gender) => (
                                        <label key={gender.gender}>
                                            <input type="checkbox" value={gender.gender} onChange={handleGenderChange} />
                                            {genderMapping[gender.gender]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        }
                        {colors.length > 1 &&
                            <div className={classes.ColorFilter}>
                                <div className={classes.Header}>
                                    <span>CULOARE</span>
                                </div>
                                <div className={classes.Filter}>
                                    {colors.map((color) => (
                                        <label key={color.color}>
                                            <input type="checkbox" value={color.color} onChange={handleColorChange} />
                                            {colorMapping[color.color]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                    
                    <div>
                        {categories.length > 0 && 
                            <div className={classes.ProductsMenu}>
                                {categories.map((category) => (
                                    <>
                                        {category.active === true && 
                                            <button className={`${classes.ProductsMenuItem} ${classes.ProductsMenuItemActive}`} value={category.category} onClick={() => handleCategoryChange(category.category)}>{categoryMapping[category.category]}</button>  
                                        }
                                        {category.active === false && 
                                            <button className={classes.ProductsMenuItem} value={category.category} onClick={() => handleCategoryChange(category.category)}>{categoryMapping[category.category]}</button>  
                                        }
                                    </>
                                ))}
                            </div>
                        }
                        <button className={classes.FiltersButton} onClick={filterToggleClickHandler}><span className={classes.FiltersButtonIcon}><BiFilter /></span> <span className={classes.FiltersButtonText}>Filtreaza</span></button>
                        
                        <ul className={classes.ProductsListing}>
                            {filteredProducts.map((product) => (
                                <li key={product.id} className={classes.ProductsItem}>
                                    <div className={classes.ItemImage}>
                                        <a href={'/products/gender/' + product.gender.toLowerCase() + '/product/' + product.code} >
                                            <div className={classes.ItemImageSrc}>
                                                <LazyLoadImage src={product.images[0].urlQuick} PlaceholderSrc="/media/onLoad.jpg" effect="blur" alt="Image Alt" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className={classes.ItemDetails}>
                                        <a href={'/products/gender/' + product.gender.toLowerCase() + '/product/' + product.code} >
                                            <h4>{product.name}</h4>
                                        </a>
                                        {productsGender === 'party' && 
                                            <>
                                            </>
                                        }
                                        {productsGender !== 'party' && 
                                            <>{(product.price.discount === null || product.price.discount === 0) &&
                                                <strong className={classes.ItemPrice}>
                                                    <span className={classes.Price}>{product.price.value.toFixed(2)} LEI</span>
                                                </strong>
                                            }
                                            {product.price.discount > 0 &&
                                                <strong className={classes.ItemPrice}>
                                                    <span className={classes.OldPrice}>{product.price.value.toFixed(2)} </span>
                                                    <span className={classes.NewPrice}>{(product.price.value * (100 - product.price.discount) / 100).toFixed(2)} LEI</span>
                                                </strong>
                                            }</>
                                        }
                                        
                                    </div>
                                </li>
                            ))}
                            {filteredProducts.length === 0 &&
                                <h4>Nu exista produse disponibile</h4>
                            }
                        </ul>
                    </div>
                </div>
            </InfiniteScroll>
            }
            {isBusy === true && 
                <div className={classes.LoadingContent}>
                    <ThreeDots color="#00BFFF" />
                </div>
            }
        </main>
    );
};

export default Products;