import React from 'react';
import Select from 'react-select';

import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { restApi } from '../../api/Source';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ProductPersonalization from '../../components/ProductPersonalization/ProductPersonalization'

import { HiOutlineShoppingBag, HiBadgeCheck } from "react-icons/hi";
import { ThreeDots } from "react-loader-spinner";

import Modal from 'react-modal';

import ReactGA from 'react-ga4';

import Fancybox from "./Fancybox.js";

import 'react-lazy-load-image-component/src/effects/blur.css';
import classes from './Product.module.css';
import './Product.css';

function Product(props) {

    // the gender of the products page
    const { productsGender } = useParams();

    // the product id
    const { productCode } = useParams();

    // if product can be personalized, then set here the personalization details (this is shared with ProductPersonalization component)
    const [includePersonalization, setIncludePersonalization] = useState(false);
    const [personalizationDetails, setPersonalizationDetails] = useState({
        price: 49, childName: '', eventDate: '', additionalDetails: ''
    });

    // if request is in progress
    const [isBusy, setBusy] = useState(true);

    // the product as received from Backend
    const [product, setProduct] = useState();

    // the age-stock constant
    const [stocks, setStocks] = useState([]);

    const [productInStock, setProductInStock] = useState(false);

    // the selected dropdown age
    const [selectedAge, setSelectedAge] = useState();

    // the image selected from the gallery (small size and big size when user clicks on it)
    const [selectedImage, setSelectedImage] = useState();
    const [selectedQuickImage, setSelectedQuickImage] = useState();

    // Review Popup
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [review, setReview] = useState({
        reviewMark: '', feedback: '', userName: '', productCode: ''
    });

    // used to display error message if personalization details are not correct
    const [personalizationError, setPersonalizationError] = useState('');

    // display message for all fields are mandatory
    const [displayReviewMandatoryFieldsMessage, setDisplayReviewMandatoryFieldsMessage] = useState(false);

    // display error message if something fails
    const [displayReviewError, setDisplayReviewError] = useState(false);
    // dynamic classes for stars colors
    const [modalReviewMark1StarClasses, setModalReviewMark1StarClasses] = useState(classes.ModalReviewMark1Star);
    const [modalReviewMark2StarClasses, setModalReviewMark2StarClasses] = useState(classes.ModalReviewMark2Star);
    const [modalReviewMark3StarClasses, setModalReviewMark3StarClasses] = useState(classes.ModalReviewMark3Star);
    const [modalReviewMark4StarClasses, setModalReviewMark4StarClasses] = useState(classes.ModalReviewMark4Star);
    const [modalReviewMark5StarClasses, setModalReviewMark5StarClasses] = useState(classes.ModalReviewMark5Star);

    // lightbox photos carousel
    const [lightboxOpen, setLightboxOpen] = useState(false);

    // Review Popup styles
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    let ageMapping = {
        MONTHS_0_3: "0-3 luni", 
        MONTHS_3_6: "3-6 luni", 
        MONTHS_6_9: "6-9 luni",
        MONTHS_9_12: "9-12 luni",
        YEARS_0_1: "0-1 ani",
        YEARS_1_2: "1-2 ani",
        YEARS_2_3: "2-3 ani",
        YEARS_3_4: "3-4 ani",
        YEARS_4_5: "4-5 ani",
        SIZE_XXS: "XXS",
        SIZE_XS: "XS",
        SIZE_S: "S",
        SIZE_M: "M",
        SIZE_L: "L",
        SIZE_XL: "XL",
        SIZE_XXL: "XXL",
        STANDARD: "Standard"
    };

    let markMapping = {
        EXCELLENT: "Excelent",
        VERY_GOOD: "Foarte multumit",
        GOOD: "Multumit",
        NOT_GOOD: "Nemultumit",
        NOT_RECOMMEND: "Nu recomand"
    };

    let markCSSMapping = {
        EXCELLENT: "100%",
        VERY_GOOD: "80%",
        GOOD: "60%",
        NOT_GOOD: "40%",
        NOT_RECOMMEND: "20%"
    };

    // google analytics
    const [initialized, setInitialized] = useState(false);

    // google analytics
    if (!window.location.href.includes("localhost")) { 
        const TRACKING_ID = "G-ZS6KCY5ZX4";
        ReactGA.initialize(TRACKING_ID);
    }

    useEffect(() => {
        window.scroll(0, 0);
        document.title = "Product " + productCode;
        restApi.get("/product/" + productCode).then(res => {
            setProduct(res.data);
            setSelectedQuickImage(res.data.images[0].urlQuick);
            setSelectedImage(res.data.images[0].url);
            setBusy(false);

            // create a label-value array based on age & stock
            {res.data.age.map(function(age) {
                stocks.push({label: ageMapping[age.age], value: age.stock.quantity, age:age.age})
            })};
        })
    }, []);

    useEffect(() => {
        if (initialized) {
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [initialized, window.location]);

    // based on the selected age, check if product is in stock
    const handleSelectAge = (age) => {
        setSelectedAge(age);
        if (age.value > 0) {
            setProductInStock(true);
        } else {
            setProductInStock(false);
        }
    }

    const addProductToCart = () => {
        // Meta Pixel
        window.fbq('track', 'AddToCart');

        // if we have personalization errors, don't add to cart
        if (includePersonalization === true && personalizationError.length > 0) {
            return;
        }

        //create a copy of our cart state, avoid overwritting existing state
        let cartCopy = {...props.cart};

        // TODO currently I take in consideration number of items in cart by productInCart (but I can have multiple products with same id with and without personalization)
        // TODO add method to determine the total number of items in cart based on product.code and selectedAge.label and replace productInCart.quantity with that number

        // find existing product by code and age, without personalization
        let productInCart = props.cart.products.find(
            (item) => product.code === item.code && 
                      selectedAge.label === item.age.label &&
                      (item.personalizationDetails === null || item.personalizationDetails === undefined));


        // product is not in cart, add new product
        // product is in cart without personalization and the new product doesn t have personalization, increase quantity
        // product is in cart without personalization and the new product has personalization, create new product
        // product is in cart with personalization, create new product
        if (productInCart) {
            if (includePersonalization) {
                // new product
                if (productInCart.quantity + 1 <= productInCart.age.value) {
                    let newProductInCart = {
                        id: product.id,
                        age: selectedAge,
                        gender: product.gender,
                        code: product.code,
                        name: product.name,
                        color: product.color,
                        image: product.images[0].url,
                        price: product.price,
                        quantity: 1,
                    };
                    personalizationDetails.productId = product.id;
                    newProductInCart.personalizationDetails = personalizationDetails;
                
                    cartCopy.products.push(newProductInCart);
                }
            } else {
                if (productInCart.quantity + 1 <= productInCart.age.value) {
                    productInCart.quantity++;
                }
            }
        } else {
            // new product
            let newProductInCart = {
                id: product.id,
                age: selectedAge,
                gender: product.gender,
                code: product.code,
                name: product.name,
                color: product.color,
                image: product.images[0].url,
                price: product.price,
                quantity: 1
            };
            if (includePersonalization) {
                personalizationDetails.productId = product.id;
                newProductInCart.personalizationDetails = personalizationDetails;
            }
            cartCopy.products.push(newProductInCart);
        }
 
        props.setCart(cartCopy);

        //make cart a string and store in local space
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart);
    }

    // Review Modal
    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
        setReview({reviewMark: '', feedback: '', userName: '', productCode: ''});
        setDisplayReviewMandatoryFieldsMessage(false);
        setDisplayReviewError(false);
        setModalReviewMark1StarClasses(classes.ModalReviewMark1Star);
        setModalReviewMark2StarClasses(classes.ModalReviewMark2Star);
        setModalReviewMark3StarClasses(classes.ModalReviewMark3Star);
        setModalReviewMark4StarClasses(classes.ModalReviewMark4Star);
        setModalReviewMark5StarClasses(classes.ModalReviewMark5Star);
    }

    // update review based on input
    const updateReview = (event, detail) => {
        if (detail === 'reviewMark') {
            setReview(oldReview => ({...oldReview, [detail]: event }));
            if (event === 'EXCELLENT') {
                setModalReviewMark1StarClasses(classes.ModalReviewMark1Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark2StarClasses(classes.ModalReviewMark2Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark3StarClasses(classes.ModalReviewMark3Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark4StarClasses(classes.ModalReviewMark4Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark5StarClasses(classes.ModalReviewMark5Star + ' ' + classes.ModalReviewMarkStarSelected);
            } else if (event === 'VERY_GOOD') {
                setModalReviewMark1StarClasses(classes.ModalReviewMark1Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark2StarClasses(classes.ModalReviewMark2Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark3StarClasses(classes.ModalReviewMark3Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark4StarClasses(classes.ModalReviewMark4Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark5StarClasses(classes.ModalReviewMark5Star);
            } else if (event === 'GOOD') {
                setModalReviewMark1StarClasses(classes.ModalReviewMark1Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark2StarClasses(classes.ModalReviewMark2Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark3StarClasses(classes.ModalReviewMark3Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark4StarClasses(classes.ModalReviewMark4Star);
                setModalReviewMark5StarClasses(classes.ModalReviewMark5Star);
            } else if (event === 'NOT_GOOD') {
                setModalReviewMark1StarClasses(classes.ModalReviewMark1Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark2StarClasses(classes.ModalReviewMark2Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark3StarClasses(classes.ModalReviewMark3Star);
                setModalReviewMark4StarClasses(classes.ModalReviewMark4Star);
                setModalReviewMark5StarClasses(classes.ModalReviewMark5Star);
            } else if (event === 'NOT_RECOMMEND') {
                setModalReviewMark1StarClasses(classes.ModalReviewMark1Star + ' ' + classes.ModalReviewMarkStarSelected);
                setModalReviewMark2StarClasses(classes.ModalReviewMark2Star);
                setModalReviewMark3StarClasses(classes.ModalReviewMark3Star);
                setModalReviewMark4StarClasses(classes.ModalReviewMark4Star);
                setModalReviewMark5StarClasses(classes.ModalReviewMark5Star);
            }
        } else {
            setReview(oldReview => ({...oldReview, [detail]: event.target.value }));
        }
    }

    // submit review
    const handleSubmitReview = () => {
        let reviewDetailsRequest =  {
            "productCode" : product.code,
            "userName": review.userName,
            "reviewMark": review.reviewMark,
            "feedback": review.feedback
        };
        if ((reviewDetailsRequest.userName === null || reviewDetailsRequest.userName === undefined || reviewDetailsRequest.userName.length === 0)
            || (reviewDetailsRequest.reviewMark === null || reviewDetailsRequest.reviewMark === undefined || reviewDetailsRequest.reviewMark.length === 0)) {
                setDisplayReviewMandatoryFieldsMessage(true);
        } else {
            setDisplayReviewMandatoryFieldsMessage(false);
            
            restApi.post("/product/reviews/add/", reviewDetailsRequest).then(res => {
                setReview({reviewMark: '', feedback: '', userName: '', productCode: ''});
                setModalReviewMark1StarClasses(classes.ModalReviewMark1Star);
                setModalReviewMark2StarClasses(classes.ModalReviewMark2Star);
                setModalReviewMark3StarClasses(classes.ModalReviewMark3Star);
                setModalReviewMark4StarClasses(classes.ModalReviewMark4Star);
                setModalReviewMark5StarClasses(classes.ModalReviewMark5Star);
                closeModal();
                setDisplayReviewError(false);
            }).catch(error => {
                // display an error inside modal
                setDisplayReviewError(true);
                setReview({reviewMark: '', feedback: '', userName: '', productCode: ''});
                setModalReviewMark1StarClasses(classes.ModalReviewMark1Star);
                setModalReviewMark2StarClasses(classes.ModalReviewMark2Star);
                setModalReviewMark3StarClasses(classes.ModalReviewMark3Star);
                setModalReviewMark4StarClasses(classes.ModalReviewMark4Star);
                setModalReviewMark5StarClasses(classes.ModalReviewMark5Star);
                console.log(error);
            });
        }      
    }

    // set the small and big size images. small size for main view of product, big size when user clicks on image to see it in detail
    const setSelectedImages = (url, urlQuick) => {
        setSelectedImage(url);
        setSelectedQuickImage(urlQuick);
    }

    const ref = useRef(null);
    const handleJumpToReviews = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <main style={{marginTop: '64px'}}>
            {isBusy === false && 
                <div className={classes.Product}>
                    {/* TODO future add breadcrumb */}
                    <div className={classes.Summary}>
                        <div className={classes.Images}>
                            <div className={classes.Gallery}>
                                {product.images.map((image, index) => (
                                    <img key={index} src={image.urlQuick} effect="blur" onClick={() => setSelectedImages(image.url, image.urlQuick)} alt="" />
                                ))}
                            </div>
                            <Fancybox options={{ dragToClose: false }}>
                            <div className={`${classes.Image} ${classes.Selected}`}>
                                <a data-fancybox="gallery" href={selectedImage}>
                                    <LazyLoadImage src={selectedQuickImage} effect="blur" alt="" />
                                </a>
                            </div>
                            </Fancybox>
                        </div>
                        <div className={classes.Border} />
                        <div className={classes.Details}>
                            <div>
                                <h1 className={classes.Title}>{product.name}</h1>
                                {product.reviews.length > 0 && 
                                    <div className={classes.MainRating}>
                                        <div className={classes.StarRating} onClick={handleJumpToReviews}>
                                            <div className={classes.StarRatingInner} style={{'width': `${[product.reviewsTotalResults.finalPercentage]}%`}}></div>
                                        </div>
                                        <div>({product.reviewsTotalResults.finalMark})</div>
                                    </div>
                                }
                                {(product.price.discount === null || product.price.discount === 0) &&
                                    <div className={classes.Price}>
                                        <h3>{product.price.value} LEI</h3>
                                    </div>
                                }
                                {product.price.discount > 0 &&
                                    <div className={classes.Price}>
                                        <span className={classes.OldPrice}>{product.price.value} LEI</span>
                                        <h3 className={classes.NewPrice}>{(product.price.value * (100 - product.price.discount) / 100)} LEI</h3>
                                    </div>
                                }   
                            </div>
                            <Select 
                                placeholder="Selecteaza marimea"
                                onChange={handleSelectAge}
                                options={stocks}
                                isSearchable={false}
                                getOptionValue={stocks => stocks.label} />
                            <div className={classes.Stocks}>
                                {productInStock === true &&
                                    <>
                                        {selectedAge.value > 1 && selectedAge.value <= 5 && 
                                            <h4>
                                                Grabeste-te! Ultimele produse ramase in stoc.
                                            </h4>
                                        }
                                        {selectedAge.value === 1 && 
                                            <h4>
                                                Grabeste-te! Ultimul produs ramas in stoc.
                                            </h4>
                                        }
                                        
                                        {/* TODO use ProductPersonalization and send product.personalizationType. If it's Single, invoke once, if it's multiple, add multiple ProductPersonalization and send type (candle/trousseau etc) */}
                                        
                                        {(product.personalizationType === 'CANDLE' || product.personalizationType === 'TROUSSEAU') && 
                                            <ProductPersonalization product={product} personalizationType={product.personalizationType} personalizationDetails={personalizationDetails} setPersonalizationDetails={setPersonalizationDetails} includePersonalization={includePersonalization} setIncludePersonalization={setIncludePersonalization} personalizationError={personalizationError} setPersonalizationError={setPersonalizationError} />
                                        }
                                        {product.personalizationType === 'CANDLE_TROUSSEAU' && 
                                            <><ProductPersonalization product={product} personalizationType='CANDLE' personalizationDetails={personalizationDetails} setPersonalizationDetails={setPersonalizationDetails} includePersonalization={includePersonalization} setIncludePersonalization={setIncludePersonalization} personalizationError={personalizationError} setPersonalizationError={setPersonalizationError} />
                                            <ProductPersonalization product={product} personalizationType='TROUSSEAU' personalizationDetails={personalizationDetails} setPersonalizationDetails={setPersonalizationDetails} includePersonalization={includePersonalization} setIncludePersonalization={setIncludePersonalization} personalizationError={personalizationError} setPersonalizationError={setPersonalizationError} /></>
                                        }
                                        {personalizationError.length > 0 &&
                                            <p>{personalizationError}</p>
                                        }
                                        <button className={classes.AddToCart} onClick={addProductToCart}>
                                            <HiOutlineShoppingBag />  Adaugare
                                        </button>
                                        <span>Estimat livrare: 3 zile lucratoare</span>
                                    </>
                                }
                                {productInStock === false && selectedAge &&
                                    <div className={classes.Available}>
                                        <span>Momentan aceasta marime nu este in stoc.</span>
                                        <br /><br />
                                        <span>Daca sunteti interesat de aceasta marime, va rugam sa ne contactati.</span>
                                    </div>
                                }
                            </div>
                            <div className={classes.AdditionalDetails}>
                                <h4>Descriere produs</h4>
                                <div className={classes.Description} dangerouslySetInnerHTML={{__html:  product.description.description}} />
                            </div>
                        </div>
                    </div>
                    
                    <div ref={ref} className={classes.Reviews}>
                        <h2 className={classes.Title}>Reviews ({product.reviews.length} de review-uri)</h2>

                        <div className={classes.AddReview}>
                            <button className={classes.AddReviewButton} onClick={openModal}>Adauga Review</button>
                        </div>
                        {product.reviews.length > 0 && 
                            <><div className={classes.GeneralRating}>
                                <div className={classes.Mark}>
                                    <p className={classes.Average}>{product.reviewsTotalResults.finalMark}</p>
                                    <div className={classes.StarRating}>
                                        <div className={classes.StarRatingInner} style={{'width': `${[product.reviewsTotalResults.finalPercentage]}%`}}></div>
                                    </div>
                                    <p className={classes.ReviewsNumber}>{product.reviews.length} de review-uri <span className="badge-check"><HiBadgeCheck /></span></p>
                                </div>
                                <div className={classes.DetailedSummary}>
                                    <div className={classes.Review5Stars}>
                                        <p>5 stele</p>
                                        <div className={classes.ProgressBar}></div>
                                        <p>({product.reviewsTotalResults.votes5Stars})</p>
                                    </div>
                                    <div className={classes.Review4Stars}>
                                        <p>4 stele</p>
                                        <div className={classes.ProgressBar}></div>
                                        <p>({product.reviewsTotalResults.votes4Stars})</p>
                                    </div>
                                    <div className={classes.Review3Stars}>
                                        <p>3 stele</p>
                                        <div className={classes.ProgressBar}></div>
                                        <p>({product.reviewsTotalResults.votes3Stars})</p>
                                    </div>
                                    <div className={classes.Review2Stars}>
                                        <p>2 stele</p>
                                        <div className={classes.ProgressBar}></div>
                                        <p>({product.reviewsTotalResults.votes2Stars})</p>
                                    </div>
                                    <div className={classes.Review1Stars}>
                                        <p>1 stele</p>
                                        <div className={classes.ProgressBar}></div>
                                        <p>({product.reviewsTotalResults.votes1Stars})</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.Conversations}>
                                <ul className={classes.List}>
                                    {product.reviews.map((review) => (
                                        <li key={review.id} className={classes.Item}>
                                            <div className={classes.UserDetails}>
                                                <p><b>{review.userName}</b> <span className="badge-check"><HiBadgeCheck /></span></p>
                                                <div className={classes.StarRating}>
                                                    <div className={classes.StarRatingInner} style={{'width': `${markCSSMapping[review.mark]}`}}></div>
                                                </div>
                                                <p>{review.createdDate}</p>
                                            </div>
                                            <div className={classes.ReviewDetails}>
                                                <h3>{markMapping[review.mark]}</h3>
                                                <p>{review.feedback}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div></>
                        }
                    </div>
                    
                    
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Review Modal"
                    >
                        <div className={classes.ModalReviewProduct}>
                            {product.name}
                        </div>

                        <div className={classes.ModalReview}>
                            <input className={classes.ModalReviewName} type="text" placeholder="Numele dvs" value={review.userName} onChange={(e) => updateReview(e, 'userName')}  />
                            <div className={classes.ModalReviewMark}>
                                <button className={modalReviewMark1StarClasses} onClick={() => updateReview('NOT_RECOMMEND', 'reviewMark')}></button>
                                <button className={modalReviewMark2StarClasses} onClick={() => updateReview('NOT_GOOD', 'reviewMark')}></button>
                                <button className={modalReviewMark3StarClasses} onClick={() => updateReview('GOOD', 'reviewMark')}></button>
                                <button className={modalReviewMark4StarClasses} onClick={() => updateReview('VERY_GOOD', 'reviewMark')}></button>
                                <button className={modalReviewMark5StarClasses} onClick={() => updateReview('EXCELLENT', 'reviewMark')}></button>
                                <p className={classes.ModalReviewMarkDescription}>{markMapping[review.reviewMark]}</p>
                            </div>
                            <textarea className={classes.ModalReviewText} type="text" placeholder="Detalii produs, livrare si interactiunea cu BabiesJoy" value={review.feedback} onChange={(e) => updateReview(e, 'feedback')}  />
                        </div>

                        {displayReviewMandatoryFieldsMessage === true &&
                            <p>*Toate campurile sunt obligatorii</p>
                        }
                        {displayReviewError === true &&
                            <p>Ceva nu a functionat. Va rugam sa incercati mai tarziu.</p>
                        }
                        
                        <div className={classes.ModalButtons}>
                            <button className={classes.ModalButtonCancel} onClick={closeModal}>Anuleaza</button>
                            <button className={classes.ModalButtonSubmit} onClick={handleSubmitReview}>Posteaza</button>
                        </div>
                        
                    </Modal>
                </div>
            }
            {isBusy === true && 
                <div className={classes.LoadingContent}>
                    <ThreeDots color="#00BFFF" />
                </div>
            }
        </main>
    );
};

export default Product;