import React from 'react';

import { useEffect, useState } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import { GrDeliver, GrDropbox, GrRevert, GrCheckmark } from "react-icons/gr";

import ReactGA from 'react-ga4';

import classes from './Home.module.css';
import './Home.css';

function Home(props) {

    const [initialized, setInitialized] = useState(false);

    // google analytics
    if (!window.location.href.includes("localhost")) { 
        const TRACKING_ID = "G-ZS6KCY5ZX4";
        ReactGA.initialize(TRACKING_ID);
    }

    useEffect(() => {
        document.title = "Home page";
        // to render admin components, when we're on the home page we have to remove isAdmin
        localStorage.setItem("isAdmin", false);
        
        if (initialized) {
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [initialized, window.location]);

    return (
        <main style={{marginTop: '64px'}}>
            <Carousel autoPlay={true}>
                <div>
                    <img src="media/carousel/boy.jpg" alt="" />
                    <a href="/products/gender/boy" className="legend">BAIETI</a>
                </div>
                <div>
                    <img src="media/carousel/girl.jpg" alt="" />
                    <a href="/products/gender/girl" className="legend">FETE</a>
                </div>
                <div>
                    <img src="media/carousel/accesorii.jpg" alt="" />
                    <a href="/products/gender/baptism" className="legend">PRODUSE BOTEZ</a>
                </div>
                <div>
                    <img src="media/carousel/accesorii.jpg" alt="" />
                    <a href="/products/gender/bed" className="legend">ACCESORII PATUT</a>
                </div>
            </Carousel>
            <div className={classes.Content}>
                <div className={classes.Gallery}>
                    <div>
                        <img src="/media/utils/noah-1.jpg" alt="" />
                    </div>
                    <div>
                        <img src="/media/utils/noah-3.jpg" alt="" />
                    </div>
                </div>
                <div className={classes.Information}>
                    <div className={classes.DeliveryTime}>
                        <span><GrDropbox /></span>
                        <h3>Livrare in 3 zile</h3>
                    </div>
                    <div className={classes.DeliveryFee}>
                        <span><GrDeliver /></span>
                        <h3>Taxa de livrare 15 LEI</h3>
                    </div>
                    <div className={classes.DeliveryFee}>
                        <span><GrCheckmark /></span>
                        <h3>Livrare GRATIS pentru comenzi ce depasesc 300 LEI</h3>
                    </div>
                    <div className={classes.ReturnTime}>
                        <span><GrRevert /></span>
                        <h3>Retur in 15 zile</h3>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Home;