import React from 'react';

import { useState } from 'react';
import { restApi } from '../../api/Source';

import classes from './AdminLogin.module.css';

const AdminLogin = props => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsername = (e) => {
        const text = e.target.value
        setUsername(text)
    }
  
    const handlePassword = (e) => {
        const text = e.target.value
        setPassword(text)
    }

    const handleFormSubmit = async() => {
        const user_object = {
            username: username,
            password: password
        };

        await restApi.post("/admin/authenticate", user_object).then(res => {
            if (res.data.jwt) {
                localStorage.setItem("Authorization", "Bearer " + res.data.jwt);
                localStorage.setItem("isAdmin", true);
            //   TODO go to admin page
                window.location.href = "/admin/home";
            }
        }).catch(error => {
          console.log("Wrong credentials");
        });
    }

    return (
        <main style={{marginTop: '64px'}}>
            <div className={classes.Content}>
                <div>
                    <input type="text"
                        className="form-control"
                        placeholder="username"
                        value={username}
                        onChange={handleUsername}
                    />
                </div>
                <div>
                    <input type="password"
                        className="form-control"
                        placeholder="password"
                        value={password}
                        onChange={handlePassword}
                    />
                </div>
                <button className="btn btn-lg btn-primary btn-block" onClick={handleFormSubmit}>
                    Login
                </button>
            </div>
        </main>
    )
}

export default AdminLogin;