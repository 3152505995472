import React from 'react';

import './Profile.css';


const profile = props => {

    return (
        <main style={{marginTop: '64px'}}>
            <div className="profile">
                Pagina Profil
            </div>
        </main>
    );
};

export default profile;