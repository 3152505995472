import React from 'react';

import { useState } from 'react';

import Select from 'react-select';

import classes from './ProductPersonalization.module.css';

const ProductPersonalization = props => {

    //TODO options depending on personalizationType (CANDLE/TROUSSEAU)
    //TODO includePersonalization should be an object with CANDLE and TROUSSEAU so that I know which personalization was selected

    let personalizationType = props.personalizationType;

    let personalizationTypeMapping = {
        CANDLE: "Doresti personalizarea lumanarii?",
        TROUSSEAU: "Doresti personalizarea lumanarii?"
    }

    const options = [
        { value: 'DA', label: 'Da (49 lei)' },
        { value: 'NU', label: 'Nu' }
    ];

    // based on the selected age, check if product is in stock
    const handleSelectPersonalization = (option) => {
        if (option.value === 'NU') {
            props.setIncludePersonalization(false);
            // reset personalization details
            props.setPersonalizationDetails({
                price: 49, childName: '', eventDate: '', additionalDetails: ''
            });
            props.setPersonalizationError('');
        } else {
            props.setIncludePersonalization(true);
            if (props.personalizationDetails.childName.length < 3) {
                props.setPersonalizationError('*Va rugam sa completati numele copilului');
            } else {
                props.setPersonalizationError('');
            }
        }
    }

    const updateDetail = (event, detail) => {
        
        props.setPersonalizationDetails(oldDetails => ({...oldDetails, [detail]: event.target.value }));
        if (detail === 'childName') {
            if (event.target.value.length < 3) {
                props.setPersonalizationError('*Va rugam sa completati numele copilului');
            } else {
                props.setPersonalizationError('');
            }
        }
    };

    return (
        <div className={classes.ProductPersonalization}>
            <Select 
                placeholder={personalizationTypeMapping[personalizationType]}
                onChange={handleSelectPersonalization}
                options={options}
                isSearchable={false}
                getOptionValue={options => options.label} />
            {props.includePersonalization === true &&
                <div className={classes.PersonalizationDetails}>
                    <div className={classes.Input}>
                        <label className={classes.Label}>Numele copilului*</label>
                        <input className={classes.Value} type="text" placeholder="Numele copilului" value={props.personalizationDetails.childName} onChange={(e) => updateDetail(e, 'childName')} />
                    </div>
                    <div className={classes.Input}>
                        <label className={classes.Label}>Data evenimentului</label>
                        <input className={classes.Value} type="text" placeholder="Data Evenimentului" value={props.personalizationDetails.eventDate} onChange={(e) => updateDetail(e, 'eventDate')} />
                    </div>
                    <div className={classes.Input}>
                        <label className={classes.Label}>Detalii suplimentare (ex. culoare)</label>
                        <input className={classes.Value} type="text" placeholder="Detalii suplimentare" value={props.personalizationDetails.additionalDetails} onChange={(e) => updateDetail(e, 'additionalDetails')} />
                    </div>
                </div>
            }
        </div>
    );

}

export default ProductPersonalization;