import React from 'react';

import './SideDrawer.css';

const sideDrawer = props => {

    let drawerClasses = 'side-drawer';
    if (props.show) {
        drawerClasses = 'side-drawer open';
    }

    return (
        <nav className={drawerClasses}>
            <ul>
                <li>
                    <a href="/products/gender/girl">Fete</a>
                </li>
                <li>
                    <a href="/products/gender/boy">Baieti</a>
                </li>
                <li>
                    <a href="/products/gender/baptism">Produse Botez</a>
                </li>
                <li>
                    <a href="/products/gender/bed">Accesorii Patut</a>
                </li>
                {/* <li>
                    <a href="/products/gender/party">Arcade Baloane</a>
                </li> */}
            </ul>
        </nav>
    ); 
};

export default sideDrawer;