import React from 'react';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { restApi } from '../../api/Source';
import { ThreeDots } from "react-loader-spinner";

import classes from './OrderDetails.module.css';

function OrderDetails(props) {
    
    const navigate = useNavigate();

    useEffect(() => {
        // Meta Pixel
        window.fbq('track', 'Purchase');

        document.title = "Order Details";
        setLocalCart(JSON.parse(localStorage.getItem("cart")));
    }, []);

    // processing price 
    const PROCESSING_FEE = 5;

    let [localCart, setLocalCart] = useState();

    // user clicked on submit order
    let [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

    // the status of the order
    const { status } = useParams();

    const [details, setDetails] = useState({
        lastName: '', firstName: '', email: '', phone: '', address: '', paymentMethod: ''
    });

    // show or hide the errors
    const [errorClass, setErrorClass] = useState(classes.Hide);

    const [errors, setErrors] = useState({
        lastName: 'Numele de familie trebuie sa contina minim 3 caractere', 
        firstName: 'Prenumele trebuie sa contina minim 4 caractere', 
        email: 'Email-ul trebuie sa fie de forma ion@provider.com', 
        phone: 'Numarul de telefon trebuie sa fie corect', 
        address: 'Adresa trebuie sa fie completa', 
        paymentMethod: 'Modalitatea de plata trebuie sa fie selectata'
    });

    // show or hide successfully submitted text
    // if landing after payment, set it to "true" by default as the payment was successful
    const [isOrderSubmitted, setIsOrderSubmitted] = useState(localStorage.getItem("cart") !== null ? false : true);

    // submit order
    const handleContinue = (event) => {
        event.preventDefault();
        if (errors.lastName !== '' || errors.firstName !== '' || errors.email !== '' || errors.phone !== '' || errors.address !== '') {
            // display errors
            setErrorClass(classes.Show);
        } else {
            setErrorClass(classes.Hide);
            let cart = JSON.parse(localStorage.getItem("cart"));

            if (cart && cart.products.length > 0) {
                setIsLoadingSubmission(true);

                if (details.paymentMethod === "RAMBURS") {
                    cart.total = cart.total + PROCESSING_FEE;
                }
                
                let completeOrderRequest = {
                    "order": cart.products,
                    "total": cart.total.toFixed(2),
                    "voucher": cart.voucher,
                    "userDetails": details
                };
                
                restApi.post("/order", completeOrderRequest).then(res => {
                    window.scroll(0, 0);
                    // empty cart
                    props.setCart({
                        products: [],
                        total: 0,
                        voucher: ''
                    });
                    localStorage.removeItem("cart");
                    // empty user details
                    setDetails({});
                    if (completeOrderRequest.userDetails.paymentMethod === "CARD") {
                        window.location.href=res.headers['location'];
                    } else {
                        // display "Successfully submitted + details about what's next: email"
                        setIsOrderSubmitted(true);
                        setIsLoadingSubmission(false);
                    }
                }).catch(error => {
                    localStorage.removeItem("cart");
                    props.setCart({
                        products: [],
                        total: 0,
                        voucher: ''
                    });
                    setDetails({});
                    window.location.href = "/order/details/failed";
                    console.log(error);
                });
            }
        }
    }

    const updateDetail = (event, detail) => {
        if (detail === 'email') {
            setDetails(oldDetails => ({...oldDetails, [detail]: event.target.value.trim() }));
        } else {
            setDetails(oldDetails => ({...oldDetails, [detail]: event.target.value }));
        }

        // adjust total price based on payment method
        if (detail === 'paymentMethod') {
            if (event.target.value === "CARD") {
                if (details.paymentMethod === 'RAMBURS') {
                    setLocalCart(oldLocalCart => ({...oldLocalCart, total: oldLocalCart.total - PROCESSING_FEE}));
                }
            } else if (event.target.value === "RAMBURS") {
                if (details.paymentMethod !== 'RAMBURS') {
                    setLocalCart(oldLocalCart => ({...oldLocalCart, total: oldLocalCart.total + PROCESSING_FEE}));
                }
            }
        }

        switch(detail) {
            case 'lastName':
                if (event.target.value.length <= 2) {
                    setErrors({
                        ...errors,
                        lastName:'Numele de familie trebuie sa contina minim 3 caractere'
                    })
                } else {
                    setErrors({
                        ...errors,
                        lastName:''
                    })
                }
                break;
            case 'firstName':
                if (event.target.value.length <= 3) {
                    setErrors({
                        ...errors,
                        firstName:'Prenumele trebuie sa contina minim 4 caractere'
                    })
                } else {
                    setErrors({
                        ...errors,
                        firstName:''
                    })
                }
                break;
            case 'email':
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(event.target.value.trim())) {
                    setErrors({
                        ...errors,
                        email:'Email-ul trebuie sa fie de forma ion@provider.com'
                    })
                } else {
                    setErrors({
                        ...errors,
                        email:''
                    })
                }
                break;
            case 'phone':
                if (event.target.value.length <= 9) {
                    setErrors({
                        ...errors,
                        phone:'Numarul de telefon trebuie sa fie corect'
                    })
                } else {
                    setErrors({
                        ...errors,
                        phone:''
                    })
                }
                break;
            case 'address':
                if (event.target.value.length <= 5) {
                    setErrors({
                        ...errors,
                        address:'Adresa trebuie sa fie completa'
                    })
                } else {
                    setErrors({
                        ...errors,
                        address:''
                    })
                }
                break;
            case 'paymentMethod':
                if (event.target.value.length <= 5) {
                    setErrors({
                        ...errors,
                        paymentMethod:'Modalitatea de plata trebuie sa fie selectata'
                    })
                } else {
                    setErrors({
                        ...errors,
                        paymentMethod:''
                    })
                }
                break;
            default:
                break;
            
        }
    };
    

    return (
        <main style={{marginTop: '64px'}}>
            {!isOrderSubmitted && status !== 'failed' && 
                <div className={classes.Content}>
                    <h1>Detaliile dumneavoastra</h1>
                    <form className={classes.Form}>
                        <div className={classes.Input}>
                            <label className={classes.Label}>Numele de familie*</label>
                            <input className={classes.Value} type="text" placeholder="Nume familie" value={details.lastName} onChange={(e) => updateDetail(e, 'lastName')} />
                        </div>
                        <div className={classes.Input}>
                            <label className={classes.Label}>Prenume*</label>
                            <input className={classes.Value} type="text" placeholder="Prenume" value={details.firstName} onChange={(e) => updateDetail(e, 'firstName')} />
                        </div>
                        <div className={classes.Input}>
                            <label className={classes.Label}>Adresa de e-mail*</label>
                            <input className={classes.Value} type="text" placeholder="Adresa de e-mail" value={details.email} onChange={(e) => updateDetail(e, 'email')} />
                        </div>
                        <div className={classes.Input}>
                            <label className={classes.Label}>Telefon*</label>
                            <input className={classes.Value} type="text" placeholder="Telefon" value={details.phone} onChange={(e) => updateDetail(e, 'phone')} />
                        </div>
                        <div className={classes.Input}>
                            <label className={classes.Label}>Adresa completa (Judet, Localitate, Strada, Numar, Bloc, Ap)*</label>
                            <input className={classes.Value} type="text" placeholder="Adresa" value={details.address} onChange={(e) => updateDetail(e, 'address')} />
                        </div>
                        <div className={classes.Input}>
                            <label className={classes.Label}>Modalitate plata*</label>
                            <select className={classes.Value} value={details.paymentMethod} onChange={(e) => updateDetail(e, 'paymentMethod')}>
                                <option disabled={true} value="">
                                    Selecteaza...
                                </option>
                                <option value="CARD">Card Online</option>
                                <option value="RAMBURS">Numerar la livrare (+ 5 LEI taxa procesare)</option>
                            </select>
                        </div>
                        {isLoadingSubmission === false && details.paymentMethod === 'CARD' && 
                            <button onClick={handleContinue}>Continua spre plata ({localCart.total.toFixed(2)} LEI)</button>
                        }
                        {isLoadingSubmission === false && details.paymentMethod === 'RAMBURS' && 
                            <button onClick={handleContinue}>Plaseaza comanda ({localCart.total.toFixed(2)} LEI)</button>
                        }
                        {isLoadingSubmission === true && 
                            <ThreeDots color="#00BFFF" />
                        }
                    </form>
                    <div className={`${classes.Error} ${errorClass}`}>
                        <ul>
                            {errors.lastName.length > 0 &&
                                <li>
                                    !{errors.lastName}
                                </li>
                            }
                            {errors.firstName.length > 0 &&
                                <li>
                                    !{errors.firstName}
                                </li>
                            }
                            {errors.email.length > 0 &&
                                <li>
                                    !{errors.email}
                                </li>
                            }
                            {errors.phone.length > 0 &&
                                <li>
                                    !{errors.phone}
                                </li>
                            }
                            {errors.address.length > 0 &&
                                <li>
                                    !{errors.address}
                                </li>
                            }
                        </ul>
                    </div>
                    <div className={classes.Info}>
                        <span>*Veti primi un e-mail cu comanda dumneavoastra imediat dupa plasarea acesteia</span>
                    </div>
                </div>
            }
            {isOrderSubmitted && (status === 'success' || status === 'pending') && 
                <div className={classes.Content}>
                    <span>Ne bucuram pentru alegerea facuta si va mai asteptam.</span>
                    <br />
                    <span>Veti primi curand un e-mail cu datele comenzii.</span>
                    <br />
                    <span>In cazul in care nu primit email de confirmare si ati ales plata cu cardul, inseamna ca ceva nu a functionat sau nu ati finalizat plata.</span>
                    <br />
                    <span>Pentru orice nelamurire, va stam la dispozitie prin una din modalitatile de contact precizate in josul paginii.</span>
                </div>
            }
            {status === 'failed' && 
                <div className={classes.Content}>
                    <span>Ne pare rau, insa ceva nu a functionat.</span>
                    <br />
                    <span>Va rugam sa incercati din nou.</span>
                    <br />
                    <span>Pentru orice nelamurire, va stam la dispozitie prin una din modalitatile de contact precizate in josul paginii.</span>
                </div>
            }
        </main>
    );
};

export default OrderDetails;
