import React, { useState, useEffect } from 'react';
import { restApi } from '../../api/Source';

import { HiOutlineMinus, HiOutlinePlus, HiTrash } from "react-icons/hi";

import classes from './Cart.module.css';

import { useNavigate } from 'react-router';

function Cart(props) {

    const navigate = useNavigate();

    let [totalCartPriceWithoutVoucher, setTotalCartPriceWithoutVoucher] = useState(0);

    let [totalProductsPrice, setTotalProductsPrice] = useState(0);

    let [totalCartPrice, setTotalCartPrice] = useState(0);

    let [includeDelivery, setIncludeDelivery] = useState(false);

    let [voucherCode, setVoucherCode] = useState('');

    let [isVoucherApplied, setIsVoucherApplied] = useState(false);

    let [voucher, setVoucher] = useState();

    let [isVoucherInvalid, setIsVoucherInvalid] = useState(false);

    // delivery price 
    const DELIVERY_FEE = 15;

    // minimum cart sum for free delivery
    const DELIVERY_FREE_MINIMUM = 300;

    let colorMapping = {
        RED: "Rosu",
        BLUE: "Albastru",
        WHITE: "Alb",
        PINK: "Roz",
        ORANGE: "Portocaliu",
        BEIGE: "Bej",
        GREY: "Gri",
        MAUVE: "Mov",
        YELLOW: "Galben",
        GREEN: "Verde",
        TRADITIONAL: "Traditional"
    };

    const updateTotalCartPrice = () => {
        let finalPrice = 0;
        props.cart.products.forEach((product) => {
            if (product.price.discount && product.price.discount > 0) {
                finalPrice += (product.price.value * (100 - product.price.discount) / 100) * product.quantity;
            } else {
                finalPrice += product.price.value * product.quantity;
            }
            // add personalization fee
            if (product.personalizationDetails !== null && product.personalizationDetails !== undefined) {
                finalPrice += product.personalizationDetails.price;
            }
        });
        
        setTotalProductsPrice(finalPrice);

        if (finalPrice < DELIVERY_FREE_MINIMUM) {
            setTotalCartPriceWithoutVoucher(finalPrice + DELIVERY_FEE);
        } else {
            setTotalCartPriceWithoutVoucher(finalPrice);
        }

        // calculate price depending on voucher
        if (voucher != null && voucher !== undefined && voucher.code !== undefined && voucher.voucherType !== undefined) {
            switch(voucher.voucherType) {
                case 'UNIQUE':
                case 'INFLUENCER':
                case 'PROMOTION':
                    if (voucher.voucherDiscount === 'AMOUNT') {
                        finalPrice = totalProductsPrice - voucher.discount;
                    } else if (voucher.voucherDiscount === 'PERCENTAGE') {
                        finalPrice = ((100 - voucher.discount) / 100) * totalProductsPrice;
                    }
                    break;
                default:
                    finalPrice = totalProductsPrice;
                    break;
            }
        }

        if (finalPrice < DELIVERY_FREE_MINIMUM) {
            if (voucher === null || voucher === undefined || voucher.voucherType !== 'DELIVERY') {
                finalPrice = finalPrice + DELIVERY_FEE;
                setIncludeDelivery(true);
            } else {
                setIncludeDelivery(false);
            }
        } else {
            setIncludeDelivery(false);
        }
        setTotalCartPrice(finalPrice);
    }

    useEffect(() => {
        document.title = "Cart";
        updateTotalCartPrice();
    }, [props.cart, voucher]);

    const decreaseQuantityForProduct = (product) => {
        let cartCopy = {...props.cart};
        // find existing product by code and age
        let productInCart = cartCopy.products.find(
            (item) => product.code === item.code && 
                    product.age.label === item.age.label && 
                    (item.personalizationDetails === null || item.personalizationDetails === undefined));

        if (productInCart && productInCart.quantity > 1) {
            productInCart.quantity--;
        }

        props.setCart(cartCopy);

        //make cart a string and store in local space
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart);

        updateTotalCartPrice();
    }

    const increaseQuantityForProduct = (product) => {
        let cartCopy = {...props.cart};
        // find existing product by code and age
        let productInCart = cartCopy.products.find(
            (item) => product.code === item.code && 
                    product.age.label === item.age.label && 
                    (item.personalizationDetails === null || item.personalizationDetails === undefined));

        if (productInCart && productInCart.quantity + 1 <= productInCart.age.value) {
            productInCart.quantity++;
        }

        props.setCart(cartCopy);

        //make cart a string and store in local space
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart);

        updateTotalCartPrice();
    }

    const removeProductFromCart = (product) => {
        let cartCopy = {...props.cart}; // make a separate copy of the array
        var index = cartCopy.products.indexOf(product);

        if (index !== -1) {
            cartCopy.products.splice(index, 1);
            props.setCart(cartCopy);
            let stringCart = JSON.stringify(cartCopy);
            localStorage.setItem("cart", stringCart);
        }

        updateTotalCartPrice();
    }

    // go to order details page
    const handleContinue = () => {

        let cartCopy = {...props.cart};

        if (voucher !== null && voucher !== undefined && voucher.code !== undefined) {
            cartCopy.voucher = voucher.code;
        } else {
            cartCopy.voucher = '';
        }
        cartCopy.total = totalCartPrice;

        props.setCart(cartCopy);
        console.log(cartCopy);
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart);

        navigate('/order/details/pending');
    }

    const applyVoucher = (event) => {
        setVoucherCode(event.target.value);
    }

    const removeVoucher = () => {
        setVoucherCode('');
        setIsVoucherApplied('');
        setVoucher();
        setIsVoucherApplied(false);
    }

    // apply voucher to cart
    const handleApplyVoucher = () => {
        if (voucherCode.length > 3) {
            // send request to BE to validate voucher
            restApi.get("/voucher/" + voucherCode).then(res => {
                if (res.data && res.data.voucherStatus === 'AVAILABLE') {
                    setVoucher(res.data);
                    setIsVoucherApplied(true);
                    setIsVoucherInvalid(false);
                } else {
                    setVoucher();
                    setIsVoucherInvalid(true);
                    setIsVoucherApplied(false);
                }
            })
        } else {
            setVoucher();
            setIsVoucherInvalid(true);
            setIsVoucherApplied(false);
        }
    }


    return (
        <main style={{marginTop: '64px'}}>
            <div className={classes.Order}>
                <h1 className={classes.Cart}>Cos de cumparaturi</h1>
                {props.cart.products && props.cart.products.length > 0 && 
                    <div className={classes.Content}>
                        <div className={classes.Products}>
                            <h1 className={classes.Title}>Produse alese</h1>
                            <ul>
                                {props.cart.products.map((product) => (
                                    <li className={classes.Product}>
                                        <img className={classes.Image} src={product.image} alt="" />
                                        <div className={classes.Details}>    
                                            <a href={'/products/gender/' + product.gender.toLowerCase() + '/product/' + product.code}>
                                                {product.personalizationDetails !== null && product.personalizationDetails !== undefined &&
                                                    <h5 className={classes.Name}>
                                                        {product.name} + Personalizare {product.personalizationDetails.childName}
                                                    </h5>
                                                }
                                                {product.personalizationDetails === null || product.personalizationDetails === undefined &&
                                                    <h5 className={classes.Name}>
                                                        {product.name}
                                                    </h5>
                                                }   
                                            </a>
                                            <div className={classes.Info}>
                                                <span className={classes.Size}>
                                                    {product.age.label}
                                                </span>
                                                <span className={classes.Color}>
                                                    {colorMapping[product.color]}
                                                </span>
                                                {product.personalizationDetails !== null && product.personalizationDetails !== undefined &&
                                                    <span className={classes.Price}>
                                                        {(((product.price.value * (100 - product.price.discount) / 100) + product.personalizationDetails.price) * product.quantity).toFixed(2)} LEI
                                                    </span>
                                                }
                                                {product.personalizationDetails === null || product.personalizationDetails === undefined &&
                                                    <span className={classes.Price}>
                                                        {((product.price.value * (100 - product.price.discount) / 100) * product.quantity).toFixed(2)} LEI
                                                    </span>
                                                }
                                            </div>
                                            <div className={classes.Actions}>
                                                <div className={classes.Quantity}>
                                                    <span>Cantitate: {product.quantity}</span>
                                                    {product.personalizationDetails === null || product.personalizationDetails === undefined &&
                                                        <>
                                                            <HiOutlineMinus onClick={() => decreaseQuantityForProduct(product)} />
                                                            <HiOutlinePlus onClick={() => increaseQuantityForProduct(product)} />
                                                        </>
                                                    }
                                                </div>
                                                <div className={classes.Delete}>
                                                    <HiTrash onClick={() => removeProductFromCart(product)} />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={classes.Border} />
                        <div className={classes.Summary}>
                            <h1 className={classes.Title}>Sumar Comanda</h1>
                            <div className={classes.Total}>
                                <span>Pret total produse: {totalProductsPrice.toFixed(2)} LEI</span>
                                <br />
                                <br />
                                {includeDelivery === true && 
                                    <span>Pret livrare: {DELIVERY_FEE} LEI</span>
                                }
                                {includeDelivery === false && 
                                    <span>Pret livrare: GRATUIT</span>
                                }
                                <br />
                                <br />
                                <span>Estimat livrare: 3 zile lucratoare</span>
                                {isVoucherApplied === false && 
                                    <h4>Total: {totalCartPrice.toFixed(2)} LEI</h4>
                                }
                                {isVoucherApplied && isVoucherInvalid === false && 
                                    <>
                                        <br /><br />
                                        {voucher.voucherType !== 'DELIVERY' &&
                                            <>
                                                {voucher.voucherDiscount === 'AMOUNT' && 
                                                    <span>Discount (din pret produse): {voucher.discount} LEI</span>
                                                }
                                                {voucher.voucherDiscount === 'PERCENTAGE' && 
                                                    <span>Discount (din pret produse): {voucher.discount}%</span>
                                                }
                                            </>
                                        }
                                        {voucher.voucherType === 'DELIVERY' &&
                                            <>
                                                <span>Discount: Livrare Gratuita</span>
                                            </>
                                        }
                                        
                                        <h4>Total: 
                                            <span className={classes.OldTotal}>{totalCartPriceWithoutVoucher.toFixed(2)}</span>
                                            <span className={classes.NewTotal}>{totalCartPrice.toFixed(2)}</span> 
                                            LEI
                                        </h4>
                                    </>
                                }
                                {includeDelivery === true && 
                                    <span className={classes.DeliveryFee}>*Pentru comenzi ce depasesc {DELIVERY_FREE_MINIMUM} LEI, livrarea este gratuita!</span>
                                }
                            </div>
                            <div className={classes.Voucher}>
                                {isVoucherApplied === false && 
                                    <>
                                        <input className={classes.Code} type="text" placeholder="Introdu voucher" value={voucherCode} onChange={(e) => applyVoucher(e)} />
                                        <button className={classes.ApplyVoucher} onClick={handleApplyVoucher}>Aplica</button>
                                    </>
                                }
                                {isVoucherApplied === true && 
                                    <>
                                        <input className={classes.Code} type="text" placeholder="Introdu voucher" value={voucherCode} disabled={true} onChange={(e) => applyVoucher(e)} />
                                        <button className={classes.ApplyVoucher} onClick={removeVoucher}>Elimina</button>
                                    </>
                                }
                            </div>
                            {isVoucherInvalid === true && 
                                <span>Voucherul introdus nu este valid sau a fost folosit</span>
                            }
                            <div className={classes.Apply}>
                                <button onClick={handleContinue}>Continua</button>
                            </div>
                        </div>
                    </div>
                }
                {(props.cart.products === null || props.cart.products.length === 0) &&
                    <div className={classes.Content}>
                        <h4>Momentan nu ati adaugat produse in cos</h4>
                    </div>
                }
            </div>
        </main>
    );
};

export default Cart;